import React from 'react'
import Helmet from 'react-helmet'
import Theme from '../theme'

import androidChrome192 from '../images/icons/android-chrome-192x192.png'
import androidChrome512 from '../images/icons/android-chrome-512x512.png'
import appleTouch from '../images/icons/apple-touch-icon.png'
import favicon16 from '../images/icons/favicon-16x16.png'
import favicon32 from '../images/icons/favicon-32x32.png'
import favicon from '../images/icons/favicon.ico'
import pinnedTab from '../images/icons/safari-pinned-tab.svg'

const Favicon = () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
    <link rel="icon" type="image/png" sizes="512x512" href={androidChrome512} />
    <link rel="icon" type="image/png" sizes="192x192" href={androidChrome192} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
    <link rel="mask-icon" href={pinnedTab} color={Theme.colors.brandDark} />
    <link rel="shortcut icon" href={favicon} />
    <link rel="manifest" href="/site.webmanifest" />
    <meta name="msapplication-config" content="/browserconfig.xml" />
    <meta name="msapplication-TileColor" content={Theme.colors.brandDark} />
    <meta name="theme-color" content={Theme.colors.brandDark} />
  </Helmet>
)

export default Favicon
