import { useEffect } from 'react'
import { makeVar, useReactiveVar } from '../reactiveVar'

const userInfoPathname = '/api/user'
const loginPathname = '/login'
const logoutPathname = '/logout'

// undefined = unknown (pending), null = unauthenticated, object = user info
export const userStateVar = makeVar(undefined)
export const useAuthentication = () => {
  const userState = useReactiveVar(userStateVar)

  return {
    // undefined: unknown, loading, otherwise true or false
    isAuthenticated: userState === undefined ? undefined : userState !== null,
    user: userState,
    loginPathname,
    logoutPathname,
  }
}

const Authentication = () => {
  useEffect(() => {
    const updateState = async () => {
      const response = await fetch(userInfoPathname)
      const data = (response.ok && (await response.json())) || null

      userStateVar(data)
    }

    updateState()
  }, [])

  return null
}

export default Authentication
