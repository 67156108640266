import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Settings from '../../settings'
import Button from '../Button/Button'
import { ChevronUp, Search } from '../../images/svg/Icons'
import QuickMenu from '../QuickMenu/QuickMenu'

export const TopBar = styled.div`
  position: relative;
`

export const WrappedHeader = styled.header`
  position: ${({ isMenuToggled, sticky }) =>
    sticky || isMenuToggled ? 'fixed' : 'absolute'};
  display: flex;
  flex-direction: column;
  top: ${({ isMenuToggled, sticky }) => (sticky || isMenuToggled ? 0 : '100%')};
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  transform: ${({ isMenuToggled, show, sticky }) =>
    // eslint-disable-next-line no-nested-ternary
    isMenuToggled
      ? 'translateY(0)'
      : sticky && !show
      ? 'translateY(calc(-100% - 60px))'
      : 'translateY(0)'};
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
  ${({ transition }) =>
    transition &&
    'transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;'}
  ${({ sticky }) => sticky && 'box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);'}

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    background-color: ${(props) =>
      props.sticky ? props.theme.colors.backgroundColor : 'transparent'};
    ${({ sticky }) => !sticky && 'transform: translateY(0);'}
    top: ${({ sticky }) => (sticky ? 0 : '100%')};
    position: ${({ sticky }) => (sticky ? 'fixed' : 'absolute')};

    transform: ${({ show, sticky }) => {
      if (sticky && !show) {
        return 'translateY(calc(-100% - 60px))'
      } else if (show && sticky && Settings.hasSiteSwitch) {
        return 'translateY(-40px)'
      } else {
        return 'translateY(0)'
      }
    }};
  }

  .hDTYmu {
    z-index: 10;
  }
`

export const FlexHeader = styled.div`
  position: static;
  display: flex;
  align-items: center;
  padding: 20px 0;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    position: relative;
    justify-content: flex-start;
    padding: ${({ sticky }) => (sticky ? '5px 0' : '20px 0')};
    transition: padding 0.3s ease-out;
  }
`
export const LeftPane = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const LogoWrapper = styled.div`
  ${({ centered }) => {
    if (centered) {
      return 'position: absolute; left: 50%; transform: translateX(-50%) translateY(-50%);'
    } else {
      return 'position: absolute; left: 60px; transform: translateY(-50%);'
    }
  }}

  ${() => {
    if (Settings.headerLogoOverflowMode === 'none') {
      return 'top: 50%;'
    } else {
      return 'top: 100%;'
    }
  }}
  z-index: 10;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    ${() => {
      if (Settings.headerLogoOverflowMode === 'none') {
        return 'position: relative;'
      } else {
        return 'position: absolute;'
      }
    }}
    left: 0;
    top: ${({ sticky }) => {
      if (sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '10px'
      } else if (!sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '20px'
      } else {
        return '0'
      }
    }};
    transform: none;
    transition: top 0.3s ease-out;
  }
`

export const Logo = styled.img`
  display: block;
  height: 100%;
  width: 80px;
  ${({ shadow }) =>
    shadow && `filter: drop-shadow(0 12px 12px rgba(0, 0, 0, .2));`}

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: ${({ sticky }) => {
      if (sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '48px'
      } else {
        return '104px'
      }
    }};
    transition: width 0.3s ease-out;
  }
`

export const StyledLink = styled(Button)(({ variation }) => [
  css`
    margin-top: 0;
  `,
  variation === 'secondary' &&
    css`
      border: 0;
      position: relative;
      padding: 10px 0;
      color: ${(props) => props.theme.colors.brand};
      text-decoration: none;

      &:hover,
      &:focus {
        color: ${(props) => props.theme.colors.black};
        background-color: transparent;
      }
    `,
])

export const StyledButton = styled(Button)`
  margin: 0;
  background: transparent;
  color: ${(props) => props.theme.colors.brand};

  :hover,
  :focus {
    background: transparent;
    color: ${(props) => props.theme.colors.black};
  }
`
export const UserImage = styled.img`
  width: 54px;
  height: 43px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  object-fit: cover;
`
export const StyledChevron = styled(ChevronUp)`
  fill: ${({ theme: { colors } }) => colors.brand};
  margin-left: 8px;
  transform: rotate(180deg);
  transition: transform 0.1s ease-in-out;
  width: 10px;
`
export const StyledQuickMenu = styled(QuickMenu)`
  display: none;
  right: 0;
  position: absolute;
  top: calc(100% + 8px);
`

export const LoggedInUser = styled.button(
  ({ theme: { fonts } }) => css`
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    font-family: ${fonts.adelle.regular}
    font-size: 16px;
    line-height: 21px;
    margin: -8px 0;
    padding: 8px 0;
  `,
)

export const Actions = styled.div(
  ({ theme: { breakpoints, fonts } }) => css`
    display: flex;
    font-family: ${fonts.adelle.regular};
    gap: 20px;

    @media (min-width: ${breakpoints.l}) {
      margin-left: auto;
      position: relative;

      a {
        font-size: 16px;
      }
    }
  `,
)

export const LoginStateContainer = styled.div(
  ({ theme: { breakpoints, colors } }) => css`
    @media (min-width: ${breakpoints.l}) {
      position: relative;
      :hover,
      :focus-within {
        ${StyledQuickMenu} {
          display: block;
        }

        ${LoggedInUser} {
          color: ${colors.brand};
        }

        ${StyledChevron} {
          fill: ${colors.black};
          transform: rotate(360deg);
        }
      }
    }
  `,
)

export const MobileSearchLink = styled(Link)`
  color: inherit;
  padding: 8px;
  margin-bottom: -3px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: none;
  }
`
export const DesktopSearchLink = styled(Link)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: block;
    color: inherit;
    padding: 8px;
  }
`
export const SearchIcon = styled(Search)``
