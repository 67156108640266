import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowRight } from '../../images/svg/Icons'

const sharedStyles = ({ theme, wide, content, variation, covering, color }) => {
  return `
    cursor: pointer;
    background: none;
    border: none;
    margin-right: 0;
    margin-top: ${theme.spacing.l};
    padding: 0;
    position: relative;
    margin-bottom: 0;
    width: ${wide === 'true' && content ? '100%' : 'auto'};
    display: ${wide === 'true' && content ? 'block' : 'inline-block'};
    border-radius: ${content ? '26px' : '26px 30px 26px 30px'};

    &:hover,
    &:focus {
      text-decoration: none;
    }
    
    ${
      !color && !content
        ? `
      &:hover .button-background,
      &:focus .button-background {
        fill: ${theme.colors.brandDark};
      }
    `
        : ''
    }
    
    ${
      variation === 'primary'
        ? `
      background-color: ${color ?? theme.colors.brand};
      color: ${theme.colors.white};
      ${!content ? 'background-color: transparent;' : ''}

      &:hover,
      &:focus {
        background-color: ${theme.colors.brandDark};
        ${!content ? 'background-color: transparent;' : ''}
      }

      svg {
        fill: ${theme.colors.white};
      }
    `
        : ''
    }

    ${
      variation === 'secondary'
        ? `
      background-color: ${theme.colors.white};
      color: ${theme.colors.brand};
      border: 1px solid ${theme.colors.greyLightest};
      ${!content ? 'background-color: transparent;' : ''}

      &:hover,
      &:focus {
        background-color: ${theme.colors.greyLightest};
        ${!content ? 'background-color: transparent;' : ''}
      }

      svg {
        fill: ${theme.colors.brand};
      }
    `
        : ''
    }

    ${
      variation === 'link'
        ? `
      background-color: transparent;
      color: ${theme.colors.brand};

      &:hover,
      &:focus {
        background-color: transparent;
        text-decoration: underline;
      }

      svg {
        fill: ${theme.colors.brand};
      }
    `
        : ''
    }

    ${
      covering === 'true'
        ? `
      position: static;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    `
        : ''
    }
  `
}

export const RealButton = styled.button`
  ${(props) => props && sharedStyles(props)}
`

export const StyledButton = styled(Link)`
  ${(props) => props && sharedStyles(props)}
`

export const StyledLink = styled(Link)`
  ${(props) => props && sharedStyles(props)}
`

export const StyledHyperlink = styled.a`
  ${(props) => props && sharedStyles(props)}
`

export const ArrowButton = styled.span`
  display: inline-block;
  position: relative;

  .button-background {
    display: block;
    width: 48px;
    height: auto;
    fill: ${({ color, theme }) => color ?? theme.colors.brand};

    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 64px;
    }
  }

  .chevron-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`

export const ChevronButton = styled.span`
  display: inline-block;
  position: relative;
  width: 64px;

  .chevron-icon path,
  &:hover .button-background path {
    fill: ${({ direction, theme }) =>
      direction === 'up' || direction === 'down'
        ? theme.colors.black
        : theme.colors.white};
  }

  .button-background path,
  &:hover .chevron-icon path {
    fill: ${({ direction, theme }) =>
      direction === 'up' || direction === 'down'
        ? theme.colors.white
        : theme.colors.black};
  }

  .button-background {
    display: block;
    width: 100%;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
    ${({ direction }) =>
      direction === 'up' || direction === 'down'
        ? `
      transform: rotate(-90deg);
    `
        : ''}
  }

  .chevron-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const ButtonInner = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: ${({ wide }) =>
    wide === 'true' ? 'space-between' : 'center'};

  ${({ variation, content }) =>
    variation === 'link'
      ? `
    padding: 2px 0;
  `
      : `
    padding: ${content ? '10px 25px' : '9px 10px 9px 0'};
  `}

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    ${({ variation, content }) =>
      variation !== 'link' &&
      `padding: ${content ? '10px 25px' : '16px 10px 16px 0'};`}
  }
`

export const ButtonText = styled.span`
  margin-right: ${({ arrow }) => (arrow ? '10px' : '0')};
`

export const StyledArrowRight = styled(ArrowRight)`
  width: ${({ content }) => (content ? '22px' : '38px')};

  ${({ hyperlink, content }) =>
    hyperlink &&
    content &&
    `
    transform: rotate(-45deg);
    width: 18px;
  `}

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: ${({ content }) => (content ? '22px' : '54px')};
  }
`
