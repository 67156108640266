/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require('react')

const { default: RootElement } = require('./src/components/RootElement')
const { default: Layout } = require('./src/components/Layout/Layout')

exports.wrapRootElement = ({ element }) => (
  <RootElement hasAuth={Boolean(process.env.GATSBY_FEATURE_AUTH0)}>
    {element}
  </RootElement>
)

exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

exports.onRouteUpdate = ({ prevLocation }) => {
  // https://github.com/gatsbyjs/gatsby/issues/10410
  // Make the previous page navigation data available on window
  if (prevLocation) {
    // @ts-ignore
    window.previousPage = prevLocation;
  }
}
