import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${(props) => (props.padding ? '0 16px' : '0')};

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => (props.padding ? '0 20px' : '0')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    ${({ size, theme }) => (size === 'm' ? `max-width: ${theme.grid.m};` : '')}
    ${({ size, theme }) => (size === 's' ? `max-width: ${theme.grid.s};` : '')}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    ${({ size, theme }) => (size === 'l' ? `max-width: ${theme.grid.l};` : '')}
    ${({ size, theme }) =>
      size === 'xl'
        ? `
      max-width: ${theme.grid.xl};
      padding: 0 32px;
    `
        : ''}
  }
`

const Container = ({ children, size = 'm', padding = true, ...rest }) => (
  <Wrapper size={size} padding={padding} {...rest}>
    {children}
  </Wrapper>
)

export default Container
